const vehicleFlagHelperMixin = {
  //   data: function() {
  //     return {};
  //   },
  methods: {
    //   Mainly created for map
    hasAnyVehicleFlag(vehicle) {
      if (vehicle.status_flags) {
        let data = vehicle.status_flags
        if (data.is_charging) {
          return false
        } else if (data.is_on_ride) {
          return false
        } else if (data.is_parking) {
          return false
        } else if (data.is_reserved) {
          return false
        }
      }

      if (vehicle.general_flags) {
        let data = vehicle.general_flags
        if (data.geo_fence_alert) {
          return false
        } else if (data.iot_fault) {
          return false
        } else if (data.missing) {
          return false
        } else if (data.low_battery) {
          return false
        }
      }

      if (vehicle.operational_flags) {
        let data = vehicle.operational_flags
        if (data.charging_pick) {
          return false
        } else if (data.maintainance) {
          return false
        } else if (data.rebalance) {
          return false
        }
      }

      return true
    },

    //   Mainly created for map
    getMarkerIconNameForVehiclePower(vehicle) {
      //console.log(vehicle);
      if (vehicle.status_flags) {
        let data = vehicle.status_flags
        if (data.is_charging) {
          return 'is_charging'
        } else if (data.is_on_ride) {
          return 'is_on_ride'
        } else if (data.is_parking) {
          return 'is_parking'
        } else if (data.is_reserved) {
          return 'is_reserved'
        }
      }

      if (vehicle.general_flags) {
        let data = vehicle.general_flags
        if (data.geo_fence_alert) {
          return 'geo_fence_alert'
        } else if (data.iot_fault) {
          return 'iot_fault'
        } else if (data.missing) {
          return 'missing'
        } else if (data.low_battery) {
          return 'low_battery'
        }
      }

      if (vehicle.operational_flags) {
        let data = vehicle.operational_flags
        if (data.charging_pick) {
          return 'charging_pick'
        } else if (data.maintainance) {
          return 'maintainance'
        } else if (data.rebalance) {
          return 'rebalance'
        }
      }

      if (vehicle.lock) {
        let data = vehicle.lock
        if (Number(data.power_level) >= 74) {
          return 'full'
        } else if (
          Number(data.power_level) >= 50 &&
          Number(data.power_level) < 74
        ) {
          return 'normal'
        } else if (
          Number(data.power_level) >= 25 &&
          Number(data.power_level) < 50
        ) {
          return 'medium'
        } else if (
          Number(data.power_level) >= 5 &&
          Number(data.power_level) < 25
        ) {
          return 'low'
        } else if (
          Number(data.power_level) > 0 &&
          Number(data.power_level) < 5
        ) {
          return 'critical'
        } else if (Number(data.power_level) == 0) {
          return 'empty'
        }
      }

      return 'full'
    },

    //   Mainly created for map
    getMarkerIconSrcForVehiclePower(vehicle) {
      return {
        url: require(`@/assets/img/status/${this.getMarkerIconNameForVehiclePower(
          vehicle
        )}_icon.png`),
        scaledSize: this.hasAnyVehicleFlag(vehicle)
          ? { width: 35, height: 40 }
          : { width: 30, height: 35 },
      }
    },
  },
}

module.exports = vehicleFlagHelperMixin
