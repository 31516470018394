<template>
  <t-modal
    name="gmap-popup-alt"
    variant="vehicleLocation"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    ref="vehicleLocation"
  >
    <header class="px-3 py-4">
      <div class="flex justify-between modal-header">
        <div class="w-full text-left">
          <div class="gm-title">{{ title }}</div>
          <div class="gm-subtitle">{{ subtitle }}</div>
        </div>

        <div class="gm-lat-lng-area" @click="copyToClipboard">
          <div>
            <div class="text-14px">
              <span class="font-medium text-14px text-oDark">Lat: </span>
              <span class="text-oGray overflow-clip" v-if="marker != null">
                {{ marker.lat ? Number(marker.lat).toFixed(8) : '--' }}
              </span>
            </div>
            <div class="text-14px">
              <span class="font-medium text-oDark">Long: </span>
              <span class="text-oGray overflow-clip" v-if="marker != null">
                {{ marker.lng ? Number(marker.lng).toFixed(8) : '--' }}
              </span>
            </div>
          </div>
          <div class="text-right cursor-pointer">
            <i class="far fa-copy" />
          </div>
        </div>
      </div>
    </header>

    <section class="w-full h-96">
      <gmap-map
        ref="map"
        :zoom="15"
        :center="center"
        map-type-id="terrain"
        style="width: 100%; height: 100%;"
        @click="setMarker"
      >
        <gmap-marker
          v-if="marker"
          :position="marker"
          :draggable="false"
          :icon="
            locationMode
              ? {
                  url: require('@/assets/img/icons/map_marker@2x.png'),
                  scaledSize: { width: 43, height: 50 },
                }
              : getMarkerIconSrcForVehiclePower(this.vehicle)
          "
          style="width: 30px; height: 30px;"
        />
        <!-- <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="true" @click="center=m.position" /> -->
      </gmap-map>
    </section>
  </t-modal>
</template>

<script>
import CopyToClipboardMixin from '@/mixins/copyToClipboardMixin'
import VehicleFlagHelperMixin from '@/mixins/vehicleFlagHelperMixin'

import { EventBus } from '@/utils/EventBus'

export default {
  name: 'GoogleMapModalAlt',
  mixins: [CopyToClipboardMixin, VehicleFlagHelperMixin],
  components: {},
  props: {
    title: {
      type: [String, Number],
      default: 'Drag PIN to locate exact location',
    },
    subtitle: {
      type: [String, Number],
      default: '',
    },
    markerIsUpdatable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getWidth() {
      if (screen.width < 1200) {
        return '800'
      }
      if ((screen.width > 1300) & (screen.width < 1600)) {
        return '1000'
      }
      return '1200'
    },
    getLatLngUrl() {
      return `https://www.google.com/maps/search/?api=1&query=${this.lat},${this.lng}`
    },
  },
  data() {
    return {
      zoom: 15,
      center: {
        lat: 10,
        lng: 10,
      },
      vehicle: null,
      marker: null,
      place: null,
      loc: null,
      lat: this.lats || '90.12',
      lng: this.lngs || '90.12',
      locationMode: false,
    }
  },
  mounted() {
    EventBus.$on('gmap-popup-alt', (vehicle) => {
      this.vehicle = vehicle
      this.loc = vehicle.location
      this.locationMode = false
      this.initGoogleMap()
    })

    EventBus.$on('gmap-popup-alt-location', (location) => {
      this.locationMode = true
      let [lat, lng] = location.split(',')
      this.lat = lat
      this.lng = lng
      this.setLatLngData()
      return
    })

    // event listeners above will be deprecated
    /* this.$mitt.on('gmap-popup-alt-coords', (location) => {
      this.locationMode = true
      let [lat, lng] = location.split(',')
      this.lat = lat
      this.lng = lng
      this.setLatLngData()
      return
    }) */
  },
  methods: {
    initGoogleMap() {
      if (this.vehicle) {
        let [lat, lng] = this.vehicle.location.split(',')
        this.lat = lat
        this.lng = lng
        this.setLatLngData()
        return
      }

      if (this.location && this.location.lat && this.location.lng) {
        this.lat = this.location.lat
        this.lng = this.location.lng
        this.setLatLngData()
        return
      }
    },
    setLatLngData() {
      this.center = {
        lat: Number(this.lat),
        lng: Number(this.lng),
      }
      this.marker = {
        lat: Number(this.lat),
        lng: Number(this.lng),
      }
    },
    setMarker(info) {
      console.log('info = ', info)
      if (this.markerIsUpdatable) {
        this.marker = {
          lat: info.latLng.lat(),
          lng: info.latLng.lng(),
        }
      }
    },
    copyToClipboard() {
      // using mixin
      this.copyContentToClipboard(this.getLatLngUrl, {
        important: true,
        title: 'Lat Long Copied',
        text: 'Lat & Long has been copied to your clipboard',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.drag {
  font-size: 16px;
  font-weight: bold;
  color: #2e2e39;
}

.gm-title {
  font-size: 19px;
  font-weight: bold;
  color: #2e2e39;
}

.gm-subtitle {
  font-size: 14px;
  font-weight: medium;
  @apply text-oLightGray;
}

.gm-lat-lng-area {
  width: 230px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d3d3d3;
  background-color: #f8f8f8;
  @apply flex justify-between items-center mt-1 text-left px-4 cursor-pointer;
}
</style>
